import { template } from '@shein/common-function'
import { shippingApplyForEnum, platformStoreTypeEnum } from 'public/src/pages/components/coupon/mall/enum.js'

// type: 券类型 3：店铺券； 2：mall券； 1：平台券
// applyFor: 适用场景 1:适用于全部商品; 2/3/7: 适用于部分商品; 5/6:适用于邮费全面/减最低邮费; 8: 适用于部分店铺
// couponTypeld: 优惠券类型，1: 减元券，2/5:折扣券(2:原价百分比; 5:应付价百分比)
// filterRule: 是否有剔除规则 0没有 1有
// platformType: 平台券仅部分店铺可用配置 1-仅自营店铺；2-仅第三方店铺；3-自营+第三方

// 平台券
const getPlateText = (applyFor, couponTypeld, filterRule, language, IS_US, platformType, shippingApplyFor, thirdStoreInfo) => {
  let plateText = ''
  // 适用于全部商品
  if (applyFor == '1') {
    plateText = fitPlateApplyForOne(couponTypeld, filterRule, language)
  }
  // 适用于部分商品
  if (['2', '3', '7'].includes(applyFor)) {
    plateText = language.SHEIN_KEY_PWA_20910 // Applies to Select Products
  }
  // 适用于邮费全面/减最低邮费
  if (['5', '6'].includes(applyFor)) {
    const isPartAndSelfStore = (shippingApplyFor == shippingApplyForEnum.PART) && (platformType == platformStoreTypeEnum.SELF)
    if (filterRule) {
      plateText = language.SHEIN_KEY_PWA_20910 // Applies to Select Products
      if (isPartAndSelfStore) {
        plateText = language.SHEIN_KEY_PWA_26331 || plateText // Applies to all products except select Marketplace products and specific products.
      }
    } else {
      plateText = language.SHEIN_KEY_PWA_20908 // Applies to All Products
      if (isPartAndSelfStore) {
        plateText = language.SHEIN_KEY_PWA_23420 || plateText // Applies to all products except select Marketplace products.
      } 
    }
  }
  // 适用于部分店铺
  if (applyFor == '8') {
    plateText = fitPlateApplyForStore(platformType, couponTypeld, filterRule, IS_US, language, thirdStoreInfo)
  }

  return plateText
}
// 平台券 applyFor == '1'
const fitPlateApplyForOne = (couponTypeld, filterRule, language) => {
  let fitText = ''
  if (couponTypeld == '1' || couponTypeld == '5') {
    if (filterRule) {
      fitText = language.SHEIN_KEY_PWA_20910 // Applies to Select Products
    } else  {
      fitText = language.SHEIN_KEY_PWA_20908 // Applies to All Products
    }
  } else if (couponTypeld == '2') {
    if (filterRule) {
      fitText = language.SHEIN_KEY_PWA_21348 // Applies to Select Products. Not Valid on Sale Items
    } else {
      fitText = language.SHEIN_KEY_PWA_20909 // Not valid on sale Items
    }
  }
  return fitText
}
// 平台券 applyFor == '8'
const fitPlateApplyForStore = (platformType, couponTypeld, filterRule, IS_US, language, thirdStoreInfo) => {
  let fitText = ''
  // 仅自营
  if (platformType == '1') {
    if (couponTypeld == '1' || couponTypeld == '5') {
      if (filterRule) {
        fitText = language.SHEIN_KEY_PWA_26331 // Applies to all products except select Marketplace products and specific products.
      } else {
        if (IS_US) {
          fitText = language.SHEIN_KEY_PWA_23412 // Applies to Non-3P Sellers Only.
        } else {
          fitText = language.SHEIN_KEY_PWA_23420 // Applicable only for Non-sellers.
        }
      }
    } else if (couponTypeld == '2') {
      if (filterRule) {
        fitText = language.SHEIN_KEY_PWA_26333 // Applies to all products except select Marketplace products, promotional products, and specific products.
      } else {
        if (IS_US) {
          fitText = language.SHEIN_KEY_PWA_23414 // Applies to Non-3P Sellers Only. Not Valid on Sale Items.
        } else {
          fitText = language.SHEIN_KEY_PWA_23422 // Applicable only for Non-sellers. Not valid for items in promotion.
        }
      }
    }
  }
  // 仅三方店铺
  if (platformType == '2') {
    if (couponTypeld == '1' || couponTypeld == '5') {
      if (filterRule) {
        if (thirdStoreInfo?.storeNum > 2) {
          // Applies to select products from {1} stores, such as {0}.
          fitText = template(thirdStoreInfo?.storesName, thirdStoreInfo?.storeNum, language.SHEIN_KEY_PWA_26497)
        } else {
          // Applies to select products from {0}.
          fitText = template(thirdStoreInfo?.storesName, language.SHEIN_KEY_PWA_26498)
        }
      } else {
        if (thirdStoreInfo?.storeNum > 2) {
          // Applies to products from {1} stores, such as {0}.
          fitText = template(thirdStoreInfo?.storesName, thirdStoreInfo?.storeNum, language.SHEIN_KEY_PWA_26499)
        } else {
          // Applies to products from {0}.
          fitText = template(thirdStoreInfo?.storesName, language.SHEIN_KEY_PWA_26500)
        }
      }
    } else if (couponTypeld == '2') {
      if (filterRule) {
        if (thirdStoreInfo?.storeNum > 2) {
          // Applies to products from {1} stores such as {0}, excluding promotional products and specific products.
          fitText = template(thirdStoreInfo?.storesName, thirdStoreInfo?.storeNum, language.SHEIN_KEY_PWA_26501)
        } else {
          // Applies to products from {0}, excluding promotional products and specific products.
          fitText = template(thirdStoreInfo?.storesName, language.SHEIN_KEY_PWA_26502)
        }
      } else {
        if (thirdStoreInfo?.storeNum > 2) {
          // Applies to products from {1} stores such as {0}, excluding promotional products.
          fitText = template(thirdStoreInfo?.storesName, thirdStoreInfo?.storeNum, language.SHEIN_KEY_PWA_26503)
        } else {
          // Applies to products from {0}, excluding promotional products.
          fitText = template(thirdStoreInfo?.storesName, language.SHEIN_KEY_PWA_26504)
        }
      }
    }
  }
  // 自营+三方店铺
  if (platformType == '3') {
    if (couponTypeld == '1' || couponTypeld == '5') {
      fitText = language.SHEIN_KEY_PWA_20910 // Applies to Select Products
    } else if (couponTypeld == '2') {
      fitText = language.SHEIN_KEY_PWA_21348 // Applies to Select Products. Not Valid on Sale Items
    }
  }
  // 1p店铺
  if (platformType == '4') {
    if (couponTypeld == '2') {
      fitText = filterRule ? language.SHEIN_KEY_PWA_28873 : language.SHEIN_KEY_PWA_28874
    } else {
      fitText = filterRule ? language.SHEIN_KEY_PWA_28875 : language.SHEIN_KEY_PWA_28876
    }
  }
  return fitText
}

// mall券
const getMallText = (applyFor, couponTypeld, filterRule, language) => {
  let mallText = ''
  // 适用于全部商品
  if (applyFor == '1') {
    if (couponTypeld == '1' || couponTypeld == '5') {
      if (filterRule) {
        mallText = language.SHEIN_KEY_PWA_20919 // Applies to Select {0} Products
      } else {
        mallText = language.SHEIN_KEY_PWA_20918 // Applies to All {0} Products
      }
    } else if (couponTypeld == '2') {
      if (filterRule) {
        mallText = language.SHEIN_KEY_PWA_21393 // Applies to Select {0} Products. Not Valid on Sale Items
      } else {
        mallText = language.SHEIN_KEY_PWA_21316 // Applies to Select {0} Products. Not Valid on Sale Items
      }
    }
  }
  // 适用于部分商品
  if (['2', '3', '7'].includes(applyFor)) {
    mallText = language.SHEIN_KEY_PWA_20919 // Applies to Select {0} Products
  }
  // 适用于邮费全面/减最低邮费
  if (['5', '6'].includes(applyFor)) {
    if (filterRule) {
      mallText = language.SHEIN_KEY_PWA_20919 // Applies to Select {0} Products
    } else {
      mallText = language.SHEIN_KEY_PWA_20918 // Applies to All {0} Products
    }
  }
  return mallText
}

// 自营店铺券
const getStoreText = (applyFor, couponTypeld, filterRule, language, isMultiMall, mallName) => {
  let storeText = ''
  // 适用于全部商品
  if (applyFor == '1') {
    if (couponTypeld == '1' || couponTypeld == '5') {
      if (filterRule) {
        // Applies to {0} products other than marketplace products and specific products.
        // Applies to products other than Marketplace products and specific products.
        storeText = isMultiMall ? template(mallName, language.SHEIN_KEY_PWA_26435) : language.SHEIN_KEY_PWA_26332 
      } else  {
        // Applies to all {0} products except Marketplace products. 
        // Applies to all products except Marketplace products. 
        storeText = isMultiMall ? template(mallName, language.SHEIN_KEY_PWA_26497) : language.SHEIN_KEY_PWA_26154 
      }
    } else if (couponTypeld == '2') {
      if (filterRule) {
        // Applies to {0} products other than Marketplace products, promotional products, and specific products.
        // Applies to products other than Marketplace products, promotional products, and specific products.
        storeText = isMultiMall ? template(mallName, language.SHEIN_KEY_PWA_26438) : language.SHEIN_KEY_PWA_26334 
      } else  {
        // Applies to all {0} products except Marketplace products or promotional products. 
        // Applies to all products except Marketplace products or promotional products.
        storeText = isMultiMall ? template(mallName, language.SHEIN_KEY_PWA_26437) : language.SHEIN_KEY_PWA_26174 
      }
    }
  }
  // 适用于部分商品
  if (['2', '3', '7'].includes(applyFor)) {
    // Applies to select {0} products other than Marketplace products.
    // Applies to select products other than Marketplace products.
    storeText = isMultiMall ? template(mallName, language.SHEIN_KEY_PWA_26625) :  language.SHEIN_KEY_PWA_26626
  }
  // 适用于邮费全面/减最低邮费
  if (['5', '6'].includes(applyFor)) {
    if (filterRule) {
      // Applies to {0} products other than marketplace products and specific products.
      // Applies to products other than Marketplace products and specific products.
      storeText = isMultiMall ? template(mallName, language.SHEIN_KEY_PWA_26435) : language.SHEIN_KEY_PWA_26332 
    } else {
      // Applies to all {0} products except Marketplace products. 
      // Applies to all products except Marketplace products. 
      storeText = isMultiMall ? template(mallName, language.SHEIN_KEY_PWA_26436) : language.SHEIN_KEY_PWA_26154 
    }
  }
  return storeText
}

// 非自营店铺券
const getSelfOperatedStoreText = ({ applyFor, mallName, storeName, language, isMultiMall }) => {
  let selfOperatedStoreText = ''
  // 多mall
  if(isMultiMall) {
    // 适用于全部商品
    if (applyFor == '1') {
      selfOperatedStoreText = template(mallName, storeName, language.SHEIN_KEY_PWA_20922)  // Applies to {0} {1} Products（占位符分别是MALL名称、店铺名称）
    }
    // 适用于部分商品
    if (['2'].includes(applyFor)) {
      selfOperatedStoreText = template(mallName, storeName, language.SHEIN_KEY_PWA_23526) // Applies to Select {0} {1} Products（占位符分别是MALL名称、店铺名称）
    }
  } else {
    // 单mall
    // 适用于全部商品
    if (applyFor == '1') {
      selfOperatedStoreText = template(storeName, language.SHEIN_KEY_PWA_23527) // Applies to {0} Products（占位符是店铺名称）
    }
    // 适用于部分商品
    if (['2'].includes(applyFor)) {
      selfOperatedStoreText = template(storeName, language.SHEIN_KEY_PWA_23528) // Applies to Select {0} Products（占位符是店铺名称）
    }
  }
  return selfOperatedStoreText
}

const getPrimeTips = (type, filterRule) => {
  let tips = []
  switch (type) {
    case '1':
      tips = ['SHEIN_KEY_PWA_20908', 'SHEIN_KEY_PWA_20910']
      break
    case '2':
      tips = ['SHEIN_KEY_PWA_20918', 'SHEIN_KEY_PWA_20919']
      break
    default:
      tips = ['SHEIN_KEY_PWA_20908', 'SHEIN_KEY_PWA_20910']
      break
  }
  return tips[filterRule]
}

// club会费券
const getClubPrimeTips = (coupon, language) => {
  const languageText = language || {}
  if (coupon.rights_info?.right_apply == 1) {
    const codeMultiStr = coupon.rights_info.product_code_info_list.map(item => item.product_code_multi).join(', ')
    return template(codeMultiStr, languageText.SHEIN_KEY_PWA_28756)
  }
  if (coupon.rights_info?.rights_cycles?.length && coupon.rights_info?.right_apply == 2) {
    const rights_cycles_map = {
      2: languageText.SHEIN_KEY_PWA_28820, // 2	季卡
      1: languageText.SHEIN_KEY_PWA_28821, // 1	月卡
      3: languageText.SHEIN_KEY_PWA_28822, // 3	年卡
      4: languageText.SHEIN_KEY_PWA_28823, // 4	半年卡
      5: languageText.SHEIN_KEY_PWA_28825, // 5	周卡
      6: languageText.SHEIN_KEY_PWA_28824, // 6	半月卡
      7: languageText.SHEIN_KEY_PWA_28826, // 7	自定义
    }
    const rightsCycles = coupon.rights_info.rights_cycles.map(item => rights_cycles_map[item]).filter(item => !!item).join(', ')
    if (rightsCycles.length) {
      return template(rightsCycles, languageText.SHEIN_KEY_PWA_28756)
    }
  }
  return ''
}

export const getApplyForText = (coupon, language = {}, SiteUID = '', isMultiMall = false) => {
  // 3：店铺券； 2：mall券； 1：平台券
  const type = coupon.couponDimension
  // 适用场景 1:适用于全部商品; 2/3/7: 适用于部分商品; 5/6:适用于邮费全面/减最低邮费; 8: 适用于部分店铺
  const applyFor = coupon.applyFor
  // ruleDimension 优惠券类型，1: 减元券，2/5:折扣券
  const couponTypeld = coupon.ruleDimension
  // filterRule  是否有剔除规则 0没有 1有
  const filterRule = Number(coupon.filterRule)
  // 类型 1-仅自营店铺；2-仅第三方店铺；3-自营+第三方
  const platformType = coupon.platformStore?.type
  // 当前站点是否为美国站点
  const IS_US = SiteUID == 'pwus' || SiteUID == 'rwmus'
  // mall 名称
  const mallName = coupon.mallInfo?.mallNameMulti || coupon.mallInfo?.mall_name_multi || ''
  // mall id
  // const mallCode = coupon.mallInfo?.mallCode || ''
  // store name
  const storeName = coupon.storeInfo?.storeNameMulti || coupon.storeInfo?.store_name_multi || ''
  // store id
  const storeCode = coupon.storeInfo?.storeCode || coupon.storeInfo?.store_code || ''

  const shippingApplyFor = coupon?.shipping_apply_for || coupon?.shippingApplyFor || -1

  // 第三方店铺信息
  const platform_store = coupon?.platformStore || coupon?.platform_store || {}
  const thirdStoresName = platform_store?.third_store_total?.third_stores?.map(item => {
    return item?.store_name_multi
  })
  const thirdStoreInfo = {
    storeNum: platform_store?.third_store_total?.store_num || 0,
    storesName: thirdStoresName?.join(','),
  }
   
  let text = ''
  if (applyFor == '9') {
    const langTemplateStr = language?.[getPrimeTips(type, filterRule)] || ''
    if (langTemplateStr) return template(mallName, langTemplateStr)
  }

  if (applyFor == '10') {
    return getClubPrimeTips(coupon, language)
  }

  // 平台券
  if (type == '1') { 
    text = getPlateText(applyFor, couponTypeld, filterRule, language, IS_US, platformType, shippingApplyFor, thirdStoreInfo)
    return text
  }

  // mall券
  if (type == '2') {
    text = getMallText(applyFor, couponTypeld, filterRule, language)
    return template(mallName, text)
  } 

  // 店铺券
  if (type == '3') {
    if (storeCode == '1') {
      text = getStoreText(applyFor, couponTypeld, filterRule, language, isMultiMall, mallName)
      return template(mallName, text)
    } else {
      return getSelfOperatedStoreText({ applyFor, mallName, storeName, language, isMultiMall })
    }
  }

  return text
}

